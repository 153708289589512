<template>

    <div class="oxygen-calculator">

        <h1>How many bottles do you want for your summit bid?</h1>

        <ul>
            <li>
                <card
                    :clouds="{ left: { enabled: true, pinTo: 'top' }, right: { enabled: false, pinTo: 'bottom' } }"
                    key="card_1">
                    <template>
                        <h2>Choose the number of bottles</h2>
                        <div class="col mb-2">
                            <img class="mr-3" src="@/assets/images/oxygen-calculator/img-calc-content1.png">
                            <button @click="handleBottleMinusClick" class="minus" :disabled="(bottles === 1)"><span></span></button>
                            <div class="meter mx-1">
                                <p>{{ bottles }}</p>
                                <p>{{ (kg*bottles).toFixed(1) }} kg ({{ (lb*bottles).toFixed(1) }} lb)</p>
                            </div>
                            <button @click="handleBottlePlusClick" class="plus" :disabled="(bottles === maxBottles)"><span></span><span></span></button>
                        </div>
                    </template>
                </card>
            </li>
            <li>
                <card
                    :clouds="{ left: { enabled: false, pinTo: 'top' }, right: { enabled: true, pinTo: 'bottom' } }"
                    key="card_2">
                    <template>
                        <h2>Choose your oxygen flow rate</h2>
                        <div class="col mb-2">
                            <img class="mr-2" src="@/assets/images/oxygen-calculator/img-calc-content2.png">
                            <button @click="handleFlowMinusClick" class="minus" :disabled="(flowRate === 1)"><span></span></button>
                            <div class="dial mx-1">
                                <p :class="getClass"><span>{{ flowRate }}</span></p>
                                <p>liters/minute</p>
                            </div>
                            <button @click="handleFlowPlusClick" class="plus" :disabled="(flowRate === maxFlowRate)"><span></span><span></span></button>
                        </div>
                    </template>
                </card>
            </li>
        </ul>

        <o2-button  @click.native="handleCalculate"
                    :label="labels.calculate"
                    icon="arrow"></o2-button>

        <p class="safety">A third of all climbers get into oxygen trouble.<br>They either bring too little or use too much too fast.<br><span>Think carefully about your safety margin.</span></p>
        
    </div>

</template>


<script>

import { mapGetters, mapActions } from 'vuex'
import { SET_BOTTLES, SET_FLOW_RATE } from '@/store/oxygenCalculator/actions'
import O2Button from './O2Button'
import Card from '@/components/oxygen-calculator/Card'

export default {

    name: 'OxygenCalculator',

    components: {
        O2Button,
        Card
    },

    data() {
        return {
            componentName: this.$options.name + ' View',
            labels: {
                calculate: 'Calculate'
            },
            maxBottles: 10,
            maxFlowRate: 4,
            kg: 2.6,
            lb: 5.7
        }
    },

    computed: {
        ...mapGetters('OxygenCalculator', {
            bottles: 'bottles',
            flowRate: 'flowRate'
        }),
        getClass: function() {
            let el = 'rate_' + this.flowRate
            el.toString()
            return el
        }
    },

    methods: {
        /**
         * @function handleCalculate
         * @description
         *
         */
        handleCalculate() {
            this.$emit('show::results')
        },
        /**
         * @function handleBottleMinusClick
         * @description
         *
         */
        handleBottleMinusClick() {
            if (this.bottles > 1) {
                this.setBottles(this.bottles-1);
            }
        },
        /**
         * @function handleBottlePlusClick
         * @description
         *
         */
        handleBottlePlusClick() {
            if (this.bottles < this.maxBottles) {
                this.setBottles(this.bottles+1);
            }
        },
        /**
         * @function handleFlowMinusClick
         * @description
         *
         */
        handleFlowMinusClick() {
            if (this.flowRate > 1) {
                this.setFlowRate(this.flowRate-1);
            }
        },
        /**
         * @function handleFlowPlusClick
         * @description
         *
         */
        handleFlowPlusClick() {
            if (this.flowRate < this.maxFlowRate) {
                this.setFlowRate(this.flowRate+1);
            }
        },

        ...mapActions('OxygenCalculator', {
            setBottles: SET_BOTTLES,
            setFlowRate: SET_FLOW_RATE
        })
    }
    
}

</script>


<style lang="scss" scoped>

.oxygen-calculator {
    margin-top: 20px;
    position: absolute;
    z-index: 25;
    width: 100%;

    & * {
        touch-action: manipulation;
    }

    @media (min-width: 576px) { // RED (SM)
        margin-top: 100px;
    }
    @media (min-width: 768px) { // GREEN (MD)

    }
    @media (min-width: 992px) { // BLUE (LG)

    }
    @media (min-width: 1200px) { // YELLOW (XL)

    }
    @media (min-width: 1400px) { // PURPLE (XXL)

    }

    & button {
        margin-top: 10px;

        @media (min-width: 576px) { // RED (SM)
            margin-top: 20px;
        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }
    }

    & h1 {
        text-transform: uppercase;
        color: #ee8c33;
        margin: 0;
        padding: 0 5%;
        font-size: 17px;
        font-weight: $font-weight-extrabold;
        line-height: 24px;
        position: relative;
        z-index: 2;

        @media (min-width: 576px) { // RED (SM)
            padding: 0 25%;
            font-size: 25px;
            line-height: 30px;
        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }
    }

    & ul {
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin: 5px 0 0;
        padding: 0 10px;

        @media (min-width: 576px) { // RED (SM)
            margin: 55px 0 0;
            padding: 0;
        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }

        & li {
            max-width: 380px;
            width: 380px;
            margin: 8px 8px;
        }
    }

    & .card {
        padding: 0 10px;

        & h2 {
            text-transform: uppercase;
            color: #101f96;
            font-size: 12px;
            font-weight: $font-weight-extrabold;
            margin: 15px 0 0;

            @media (min-width: 576px) { // RED (SM)
                margin: 30px 0 0;
            }
            @media (min-width: 768px) { // GREEN (MD)

            }
            @media (min-width: 992px) { // BLUE (LG)

            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }
        }

        & .col {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 105px;
            margin-top: 10px;

            @media (min-width: 576px) { // RED (SM)
                height: 115px;
            }
            @media (min-width: 768px) { // GREEN (MD)

            }
            @media (min-width: 992px) { // BLUE (LG)

            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }

            & img {
                height: 85px;
            }

            & .dial,
            & .meter {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-self: center;
                width: 110px;

                & p {
                    text-transform: uppercase;
                    margin: 0;
                    line-height: 100%;
                    font-weight: $font-weight-bold;
                }

                & p:nth-child(1) {
                    color: #17224b;
                    font-size: 70px;
                }

                & p:nth-child(2) {
                    color: #4c7bbb;
                    font-size: 10px;
                    overflow: visible;

                    &::before {
                        content: '';
                        background-image: url('../../assets/images/oxygen-calculator/img-calc-barbell.png');
                        width: 15px;
                        height: 8px;
                        display: inline-block;
                        background-size: 15px 8px;
                        margin-right: 5px;
                    }
                }
            }

            & .dial {
                width: auto;

                & p:nth-child(1) {
                    color: #17224b;
                    background-image: url('../../assets/images/oxygen-calculator/img-calc-ring.png');
                    background-size: 85px 340px;
                    
                    font-size: 45px;
                    width: 85px;
                    height: 85px;
                    margin: 15px 0 10px 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    & span {
                        margin-top: 0;
                    }

                    &.rate_1 {
                        background-position: 0px 0px;
                    }

                    &.rate_2 {
                        background-position: 0px -85px;
                    }

                    &.rate_3 {
                        background-position: 0px -170px;
                    }

                    &.rate_4{
                        background-position: 0px -255px;
                    }
                }

                & p:nth-child(2) {

                    &::before {
                        content: '';
                        background-image: none;
                        display: none;
                    }
                }
            }

            & button {
                border: none;
                background: white;
                width: 45px;
                height: 45px;
                margin: 0;
                padding: 0;
                border-radius: 100%;
                cursor: pointer;
                box-shadow: 0 0 16px rgba(12, 20, 40, .25);
                display: flex;
                justify-content: center;
                align-items: center;

                & span {
                    background: #5c9dcc;
                    display: inline-block;
                    width: 17px;
                    height: 4px;
                }

                &.plus {
                    & span:nth-child(2) {
                        position: absolute;
                        width: 4px;
                        height: 17px;
                    }
                }
            }

            & button[disabled] {
                opacity: .3;
                cursor: unset;
            }
        }
    }

    & p.safety {
        color: #4d5384;
        font-size: 12px;
        font-weight: $font-weight-medium;
        margin: 20px auto 0;
        padding: 0 30px;
        position: relative;
        z-index: 2;
        line-height: 20px;

        @media (min-width: 576px) { // RED (SM)
            margin: 50px auto 0;
            padding: 0;
            font-size: 16px;
            line-height: 26px;
        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }

        & span {
            font-weight: $font-weight-bold;
        }
    }
}

</style>