<template>

    <div class="oxygen-calculator-results">

        <img class="midCloud" src="@/assets/images/oxygen-calculator/img-card-cloud-btm-right.png">

        <card class="slideResults"
            :class="getClass"
            :clouds="{ left: { enabled: false, pinTo: 'sm' }, right: { enabled: false, pinTo: 'sm' } }"
            key="card_1">
            <template>
                <img class="badge" src="@/assets/images/oxygen-calculator/img-results-badge.png">
                <h1>Your Results</h1>
                <div class="slider">
                    <ul>
                        <li>Not Enough</li>
                        <li>Just Right</li>
                        <li>Too Much</li>
                    </ul>
                    <div class="slide">
                        <div class="circ"></div>
                        <img class="mtn" src="@/assets/images/oxygen-calculator/img-results-slider-mtn.png">
                        <p>{{ result }}</p>
                    </div>
                </div>
            </template>
        </card>

        <o2-button @click.native="handleTryAgain"
                    :label="labels.tryagain"
                    icon="retry"></o2-button>

       <card class="results"
            :clouds="{ left: { enabled: true, pinTo: 'sm' }, right: { enabled: false, pinTo: 'sm' } }"
            key="card_2">
            <template>
                <ul class="cards">
                    <li>
                        <h2>Number Of Bottles</h2>
                        <card class="cardHeader"
                            :clouds="{ left: { enabled: false, pinTo: 'sm' }, right: { enabled: false, pinTo: 'sm' } }"
                            key="card_3">
                            <template>
                                <div class="cols">
                                    <img src="@/assets/images/oxygen-calculator/img-calc-content1.png">
                                    <div class="col">
                                        <p>{{ bottles }}</p>
                                        <p>Weight:<br>{{ (kg*bottles).toFixed(1) }} kg ({{ (lb*bottles).toFixed(1) }} lb)</p>
                                    </div>
                                </div>
                            </template>
                        </card>
                    </li>
                    <li>
                        <h2>Oxygen Flow Rate</h2>
                        <card class="cardHeader"
                            :clouds="{ left: { enabled: false, pinTo: 'sm' }, right: { enabled: false, pinTo: 'sm' } }"
                            key="card_4">
                            <template>
                                <div class="cols">
                                    <img src="@/assets/images/oxygen-calculator/img-calc-content2.png">
                                    <div class="col">
                                        <p>{{ flowRate }}</p>
                                        <p>{{ litersCopy }}<br>per minute</p>
                                    </div>
                                </div>
                            </template>
                        </card>
                    </li>
                </ul>
                <p class="resultsCopy">{{ bottlesResult }}</p>
            </template>
        </card>

        <p class="legal"><span>For educational use only</span><br>How much oxygen you carry can be subject to many variables. Make sure you check with an experienced guide before attempting to climb Mount Everest.</p>
        
    </div>

</template>


<script>

import { mapGetters, mapActions } from 'vuex'
import { SET_BOTTLES, SET_FLOW_RATE } from '@/store/oxygenCalculator/actions'
import O2Button from './O2Button'
import Card from '@/components/oxygen-calculator/Card'

export default {

    name: 'OxygenCalculatorResults',

    components: {
        O2Button,
        Card
    },

    data() {
        return {
            componentName: this.$options.name + ' View',
            labels: {
                tryagain: 'Try Again'
            },
            result: '',
            kg: 2.6,
            lb: 5.7,
            bottlesCopy: [
                {
                    notEnough: 'You might want to rethink this. That’s probably not enough bottled oxygen at the right flow rate to get you safely up and down from the summit.'
                },
                {
                    justRight: 'While there are many variables that affect the amount of oxygen you’ll need to carry, that sounds about right. According to the Nepal National Mountain Guides Association, climbers carry at least two spare bottles.'
                },
                {
                    tooMuch: 'You might want to rethink this. You might be trying to carry too many bottles, or you might be setting your flow rate so high that you use up your oxygen too quickly.'
                }
            ],
            flowRateCopy: [
                'Watch your flow. You might not be getting enough air.',
                'Most climbers set their flow to 2 for most of the climb.',
                'Watch your flow. You might be using your air too quickly.',
                'Watch your flow. At this rate, you will be consuming your air quickly.'
             ],
            bottlesResult: '',
            flowRateResult: '',
            litersCopy: ''
        }
    },

    created() {
        if(this.bottles === 1 && this.flowRate <= 4) {
            this.result = 'Not Enough'
        }
        if(this.bottles === 2 && this.flowRate > 1) {
            this.result = 'Not Enough'
        }
        if(this.bottles === 3 && this.flowRate > 2) {
            this.result = 'Not Enough'
        }
        if(this.bottles === 4 && this.flowRate >= 3) {
            this.result = 'Not Enough'
        }
        if(this.bottles === 5 && this.flowRate > 3) {
            this.result = 'Not Enough'
        }
        if(this.bottles === 2 && this.flowRate === 1) {
            this.result = 'Just Right'
        }
        if(this.bottles === 3 && (this.flowRate >= 1 && this.flowRate < 3)) {
            this.result = 'Just Right'
        }
        if(this.bottles === 4 && (this.flowRate > 1 && this.flowRate < 3)) {
            this.result = 'Just Right'
        }
        if(this.bottles === 5 && (this.flowRate > 1 && this.flowRate < 4)) {
            this.result = 'Just Right'
        }
        if((this.bottles === 6 || this.bottles === 7) && this.flowRate >= 2) {
            this.result = 'Just Right'
        }
        if((this.bottles === 8 || this.bottles === 9 || this.bottles === 10) && this.flowRate >= 3) {
            this.result = 'Just Right'
        }
        if(this.bottles === 4 && this.flowRate === 1) {
            this.result = 'Too Much'
        }
        if(this.bottles === 5 && this.flowRate < 2) {
            this.result = 'Too Much'
        }
        if((this.bottles === 6 || this.bottles === 7) && this.flowRate < 2) {
            this.result = 'Too Much'
        }
        if(this.bottles === 8 && this.flowRate < 3) {
            this.result = 'Too Much'
        }
        if((this.bottles === 9 || this.bottles === 10) && this.flowRate < 3) {
            this.result = 'Too Much'
        }
        if(this.result === 'Not Enough') {
            this.bottlesResult = this.bottlesCopy[0].notEnough
        }
        if(this.result === 'Just Right') {
            this.bottlesResult = this.bottlesCopy[1].justRight
        }
        if(this.result === 'Too Much') {
            this.bottlesResult = this.bottlesCopy[2].tooMuch
        }
        this.flowRateResult = this.flowRateCopy[this.flowRate-1]
        this.litersCopy = this.flowRate === 1 ? 'Liter' : 'Liters'
    },

    computed: {
        ...mapGetters('OxygenCalculator', {
            bottles: 'bottles',
            flowRate: 'flowRate'
        }),
        getClass: function() {
            let el = ''
            if(this.result === 'Not Enough') {
                el = 'notenough'
            }
            if(this.result === 'Just Right') {
                el = 'justright'
            }
            if(this.result === 'Too Much') {
                el = 'toomuch'
            }
            return el
        }
    },

    methods: {
        handleTryAgain() {
            this.$emit('show::results')
        },

        ...mapActions('OxygenCalculator', {
            setBottles: SET_BOTTLES,
            setFlowRate: SET_FLOW_RATE
        })
    },

    beforeDestroy() {
        this.setBottles(1);
        this.setFlowRate(1);
    }
    
}

</script>


<style lang="scss" scoped>

.oxygen-calculator-results {
    margin-top: 35px;
    position: absolute;
    z-index: 25;
    width: 100%;

    @media (min-width: 576px) { // RED (SM)
        margin-top: 50px;
    }
    @media (min-width: 768px) { // GREEN (MD)

    }
    @media (min-width: 992px) { // BLUE (LG)

    }
    @media (min-width: 1200px) { // YELLOW (XL)

    }
    @media (min-width: 1400px) { // PURPLE (XXL)

    }

    & button {
        margin-top: 15px;

        @media (min-width: 576px) { // RED (SM)
            margin-top: 20px;
        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }
    }

    & .legal {
        color: #4d5384;
        font-weight: $font-weight-medium;
        font-size: 8px;
        width: auto;
        margin: 10px 25px;

        @media (min-width: 576px) { // RED (SM)
            font-size: 11px;
            width: 400px;
            margin: 30px auto 0;
        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }

        & span {
            text-transform: uppercase;
            font-weight: $font-weight-bold;
        }
    }

    & .midCloud {
        position: fixed;
        width: 425px;
        height: 305px;
        top: -45px;
        left: -20%;
        z-index: 1;

        @media (min-width: 576px) { // RED (SM)
            top: -25px;
            left: 22%;
        }
        @media (min-width: 768px) { // GREEN (MD)
            left: 28%;
        }
        @media (min-width: 992px) { // BLUE (LG)
            left: 33%;
        }
        @media (min-width: 1200px) { // YELLOW (XL)
            left: 35%;
        }
        @media (min-width: 1400px) { // PURPLE (XXL)
            left: 37%;
        }
    }

    & .results {
        display: flex;
        flex-direction: row;
        justify-content: center;
        max-width: 100%;
        padding: 0 15px;
        margin: 20px 20px;

        @media (min-width: 576px) { // RED (SM)
            max-width: 570px;
            padding: 0;
            margin: 35px auto 0;
        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }

        & ul {
            list-style: none;
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;

            & li {
                display: flex;
                flex-direction: column;

                & h2 {
                    color: #101f96;
                    text-transform: uppercase;
                    font-size: 10px;
                    font-weight: $font-weight-extrabold;
                    margin: 20px 0 11px;

                    @media (min-width: 576px) { // RED (SM)
                        font-size: 12px;
                        margin: 35px 0 11px;
                    }
                    @media (min-width: 768px) { // GREEN (MD)

                    }
                    @media (min-width: 992px) { // BLUE (LG)

                    }
                    @media (min-width: 1200px) { // YELLOW (XL)

                    }
                    @media (min-width: 1400px) { // PURPLE (XXL)

                    }
                }

                & .card {
                    max-width: 236px;
                    display: flex;
                    width: 236px;
                    height: 65px;
                    padding: 0;
                    margin: 0;
                    border-radius: 11px;

                    & .cols {
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        width: 100%;

                        & img {
                            height: 65px;
                        }

                        & .col {
                            display: flex;
                            align-items: center;

                            & p {
                                margin: 0;
                            }

                            & p:nth-child(1) {
                                color: #0f005d;
                                font-size: 40px;
                                font-weight: $font-weight-extrabold;
                                margin-right: 5px;
                            }

                            & p:nth-child(2) {
                                text-transform: uppercase;
                                color: #4c7bbb;
                                font-size: 11px;
                                font-weight: $font-weight-extrabold;
                                text-align: left;
                                margin-top: 6px;
                            }
                        }
                    }
                }
            }
        }

        & .resultsCopy {
            color: #4d5384;
            font-weight: $font-weight-medium;
            margin: 20px 0;
            padding: 0 15px;
            font-size: 14px;
            line-height: 20px;

            @media (min-width: 576px) { // RED (SM)
                margin: 30px 0;
                padding: 0 40px;
                font-size: 15px;
                line-height: 24px;
            }
            @media (min-width: 768px) { // GREEN (MD)

            }
            @media (min-width: 992px) { // BLUE (LG)

            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }
        }
    }

    & .slideResults {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        padding: 0 15px;
        margin: 0 20px;

        @media (min-width: 576px) { // RED (SM)
            margin: 0 30px;
            padding: 0 34px;
        }
        @media (min-width: 768px) { // GREEN (MD)
            margin: 0 50px;
        }
        @media (min-width: 992px) { // BLUE (LG)
            max-width: 775px;
            margin: 0 auto;
        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }

        & .badge {
            position: relative;
            top: -22px;
            width: 62px;
            height: 72px;

            @media (min-width: 576px) { // RED (SM)
                top: -30px;
            }
            @media (min-width: 768px) { // GREEN (MD)

            }
            @media (min-width: 992px) { // BLUE (LG)

            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }
        }

        & h1 {
            position: relative;
            top: -25px;
            text-transform: uppercase;
            color: #0f005d;
            font-size: 20px;
            font-weight: $font-weight-extrabold;
            margin: 0;

            @media (min-width: 576px) { // RED (SM)
                font-size: 25px;
                top: -30px;
            }
            @media (min-width: 768px) { // GREEN (MD)

            }
            @media (min-width: 992px) { // BLUE (LG)

            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }
        }

        & .slider {
            display: flex;
            background: linear-gradient(90deg,  #a5e8f6 0%,#5956e9 50%,#a5e8f6 100%);
            height: 30px;
            width: 100%;
            border-radius: 30px;
            margin-bottom: 65px;
            margin-top: -10px;

            @media (min-width: 576px) { // RED (SM)
                height: 40px;
                border-radius: 40px;
                margin-bottom: 80px;
                margin-top: 0;
            }
            @media (min-width: 768px) { // GREEN (MD)

            }
            @media (min-width: 992px) { // BLUE (LG)

            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }

            & ul {
                list-style: none;
                width: 100%;
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                @media (min-width: 576px) { // RED (SM)
                    justify-content: space-between;
                }
                @media (min-width: 768px) { // GREEN (MD)
                    justify-content: space-around;
                }
                @media (min-width: 992px) { // BLUE (LG)

                }
                @media (min-width: 1200px) { // YELLOW (XL)

                }
                @media (min-width: 1400px) { // PURPLE (XXL)

                }

                & li {
                    text-transform: uppercase;
                    color: #9197bd;
                    font-size: 10px;
                    font-weight: $font-weight-bold;
                    margin-top: 55px;
                    align-self: flex-start;
                    
                    @media (min-width: 576px) { // RED (SM)
                        font-size: 17px;
                        margin-top: 68px;
                    }
                    @media (min-width: 768px) { // GREEN (MD)

                    }
                    @media (min-width: 992px) { // BLUE (LG)

                    }
                    @media (min-width: 1200px) { // YELLOW (XL)

                    }
                    @media (min-width: 1400px) { // PURPLE (XXL)

                    }
                }
            }

            & .slide {
                display: flex;
                justify-content: center;
                width: 111px;
                height: 74px;
                align-self: flex-start;
                position: absolute;

                @media (min-width: 576px) { // RED (SM)
                    width: 147px;
                    height: 100px;
                }
                @media (min-width: 768px) { // GREEN (MD)

                }
                @media (min-width: 992px) { // BLUE (LG)

                }
                @media (min-width: 1200px) { // YELLOW (XL)

                }
                @media (min-width: 1400px) { // PURPLE (XXL)

                }

                & .circ {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 41px;
                    height: 41px;
                    margin-top: -5px;

                    @media (min-width: 576px) { // RED (SM)
                        width: 56px;
                        height: 56px;
                        margin-top: -8px;
                    }
                    @media (min-width: 768px) { // GREEN (MD)

                    }
                    @media (min-width: 992px) { // BLUE (LG)

                    }
                    @media (min-width: 1200px) { // YELLOW (XL)

                    }
                    @media (min-width: 1400px) { // PURPLE (XXL)

                    }
                    
                    &::before {
                        content: '';
                        background: #a1d8fa;
                        width: 100%;
                        height: 100%;
                        display: block;
                        border-radius: 56px;
                        opacity: .4;
                    }

                    &::after {
                        content: '';
                        width: 20px;
                        height: 20px;
                        display: block;
                        border: 5px solid white;
                        border-radius: 20px;
                        position: absolute;
                    }
                }

                & .mtn {
                    position: absolute;
                    width: 100%;
                    bottom: 0px;
                }

                & p {
                    background: #0f005d;
                    padding: 4px 8px;
                    border-radius: 6px;
                    text-transform: uppercase;
                    color: white;
                    font-size: 12px;
                    font-weight: $font-weight-bold;
                    position: absolute;
                    bottom: 2.5px;
                    margin: 0;

                    @media (min-width: 576px) { // RED (SM)
                        font-size: 17px;
                        bottom: 4px;
                    }
                    @media (min-width: 768px) { // GREEN (MD)

                    }
                    @media (min-width: 992px) { // BLUE (LG)

                    }
                    @media (min-width: 1200px) { // YELLOW (XL)

                    }
                    @media (min-width: 1400px) { // PURPLE (XXL)

                    }
                }
            }
        }

        &.notenough {
            & .slider {
                & ul {
                    & li:nth-child(1) {
                        opacity: 0;
                    }
                }
                & .slide {
                    left: 0%;

                    @media (min-width: 576px) { // RED (SM)
                        left: 0%;
                    }
                    @media (min-width: 768px) { // GREEN (MD)
                        left: 5%;
                    }
                    @media (min-width: 992px) { // BLUE (LG)

                    }
                    @media (min-width: 1200px) { // YELLOW (XL)

                    }
                    @media (min-width: 1400px) { // PURPLE (XXL)

                    }
                }
            }
        }

        &.justright {
            & .slider {
                & ul {
                    & li:nth-child(2) {
                        opacity: 0;
                    }
                }
                & .slide {
                    left: 50%;
                    margin-left: -55.5px;

                    @media (min-width: 576px) { // RED (SM)
                        left: 0%;
                        margin-left: -73.5px;
                    }
                    @media (min-width: 768px) { // GREEN (MD)
                        left: 50%;
                        margin-left: -73.5px;
                    }
                    @media (min-width: 992px) { // BLUE (LG)

                    }
                    @media (min-width: 1200px) { // YELLOW (XL)

                    }
                    @media (min-width: 1400px) { // PURPLE (XXL)

                    }
                }
            }
        }

        &.toomuch {
            & .slider {
                & ul {
                    & li:nth-child(3) {
                        opacity: 0;
                    }
                }
                & .slide {
                    left: 68%;

                    @media (min-width: 576px) { // RED (SM)
                        left: 68%;
                    }
                    @media (min-width: 768px) { // GREEN (MD)
                        left: 75%;
                    }
                    @media (min-width: 992px) { // BLUE (LG)

                    }
                    @media (min-width: 1200px) { // YELLOW (XL)

                    }
                    @media (min-width: 1400px) { // PURPLE (XXL)

                    }
                }
            }
        }
    }
}

</style>