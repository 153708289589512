<template>

    <div class="oxygen-calculator-widget">
    
        <transition name="fade" mode="out-in">
            <oxygen-calculator v-if="!showResults" @show::results="toggleResultsContent"></oxygen-calculator>
        </transition>
        
        <transition name="fade" mode="out-in">
            <oxygen-calculator-results v-if="showResults" @show::results="toggleResultsContent"></oxygen-calculator-results>
        </transition>

    </div>

</template>


<script>

import OxygenCalculator from '@/components/oxygen-calculator/OxygenCalculator'
import OxygenCalculatorResults from '@/components/oxygen-calculator/OxygenCalculatorResults'

export default {

    name: 'OxygenCalculatorWidget',

    components: {
        OxygenCalculator,
        OxygenCalculatorResults
    },
    
    data() {
        return {
            componentName: this.$options.name + ' View',
            showResults: false
        }
    },
    
    created() {
        this.$emit('hide::landingContent')
    },
    
    mounted() {
        
    },
    
    methods: {
        /**
         * @function toggleResultsContent
         * @description
         *
         */
        toggleResultsContent() {
            this.showResults = !this.showResults
        }
    }
    
}

</script>


<style lang="scss" scoped>

.oxygen-calculator-widget {
    position: relative;
    z-index: 25;
    transform: translateZ(1000px);
}

</style>
